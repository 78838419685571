/** @jsx jsx */
import { jsx } from 'theme-ui';

import React from 'react';
import {
  Grid,
  Flex,
  Box,
  Heading,
  Text,
  Input,
  Textarea,
  Button,
  Label,
} from 'theme-ui';
import { graphql, Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import {
  AiOutlineInstagram,
  AiOutlineFacebook,
  AiOutlineWhatsApp,
  AiOutlineMail,
} from 'react-icons/ai';
import { Helmet } from 'react-helmet';
import { Trans, t } from '@lingui/macro';

import Breadcrumbs from '../../../plugins/storefront/src/components/Breadcrumbs';

const HairColorChoosePage = (props) => {
  const { storeName } = props.data.site.siteMetadata.gatsbyStorefrontConfig;
  return (
    <>
      <Helmet
        title={t({
          id: `HairColorChoose.Title`,
          message: `Choose hair color`,
        })}
        titleTemplate={t`%s — ${storeName}`}
        defer={false}
      >
        <meta
          name="description"
          content={t({
            id: `HairColorChoose.MetaDescription`,
            message: `Choose a hair extensions color with photos`,
          })}
        />
      </Helmet>
      <Flex
        my={3}
        py={[2, 3, 4]}
        sx={{
          width: '100%',
          bg: '#f4f1eb',
          justifyContent: 'center',
        }}
      >
        <Box mx={3}>
          <Breadcrumbs
            collectionTitle={t({
              id: `HairColorChoose.BreadcrumbsParentTitle`,
              message: `Guides`,
            })}
            collectionPath={'/guides'}
            productTitle={t({
              id: `HairColorChoose.BreadcrumbsTitle`,
              message: `Choose hair color`,
            })}
            separator="/"
          />
        </Box>
      </Flex>

      <Box
        mx="auto"
        px={[3, 2]}
        mt={[1, 4]}
        sx={{
          width: '100%',
          maxWidth: 1300,
        }}
      >
        <Heading as="h1" sx={{ fontSize: [4, 5] }}>
          <Trans id="HairColorChoose.Header">Choose hair color</Trans>
        </Heading>
      </Box>

      <Flex
        columns={[1, 2]}
        mx="auto"
        px={[3, 2]}
        mb={4}
        sx={{
          width: '100%',
          maxWidth: 1300,
        }}
      >
        <Box>
          <Heading
            as="h2"
            color="primary"
            my={[3, 4]}
            sx={{ fontSize: [3, 4] }}
          >
            <Trans id="HairColorChoose.Option1_Header">
              Choose by photos and videos
            </Trans>
          </Heading>
        </Box>
      </Flex>
    </>
  );
};

export const HairColorChoosePageQuery = graphql`
  query {
    site {
      siteMetadata {
        gatsbyStorefrontConfig {
          storeName
        }
      }
    }
  }
`;
export default HairColorChoosePage;
